import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Pagination from '../components/Pagination';
import Button from '../components/Button';
import PreHeader from '../components/PreHeader';
import styled, { ThemeProvider } from 'styled-components';
import { cwTheme } from '../theme/theme.js';
import Img from 'gatsby-image';
import SignUpForm from '../components/SignUpForm';

const BlogHero = styled.section`
	padding: 0;
	text-align: center;
	padding-top: 1rem;
	background-color: ${cwTheme.white};
`;

const Container = styled.div`
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 1rem;
`;

const BlogHeader = styled.h2`
	margin-top: 0;
	font-family: ${cwTheme.serif};
	margin-bottom: 1rem;
`;

const BlogMain = styled.section``;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column-reverse;
	max-width: 55rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 .5rem;

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		flex-direction: row;
		padding: 0 1rem;
	}
`;
const IndexContainer = styled.div`
	flex: 4;
	margin-left: 0.5rem;
	margin-right: 0.5rem;

	display: grid;

	grid-template-columns: 1fr;
	grid-template-rows: auto;
	grid-gap: 1rem;
`;

const PostCard = styled.div`
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	border: 1px solid #e4e4e4;
	border-radius: ${cwTheme.borderRadius};
`;

const BlogLinkWrapper = styled.div`
	min-height: 100%;
	background: rgba(255, 255, 255, 1);
	text-decoration: none;
	color: #444;
	display: flex;
	flex-direction: column;

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		flex-direction: row;
	}

	article {
		padding-bottom: .25rem;
		display: flex;
		flex-direction: column;
		flex: 1;
		padding: 0 10px 5px 10px;
	}
`;

const PostTitle = styled(Link)`
	margin-top: .2em;
	line-height: 1.18421053em;
	display: block;
	font-weight: 600;
	color: inherit;
	text-decoration: none;
	display: block;
	font-size: 1.563em;


	&:hover {
		color: ${cwTheme.blackHover}
	}
	
`;

const ImageLink = styled(Link)`
flex-grow: 1;
`;

const PostExcerpt = styled.p`
	flex: 1;
	margin-top: 0;
`;

const Date = styled.span`
	margin-bottom: .2em;
	color: ${cwTheme.secondaryBlue};
	text-transform: uppercase;
	margin-top: 5px;
	font-weight: 600;
`;

const BlogHome = ({ data, pageContext }) => {
	console.log(data);
	console.log(pageContext);

	const events = data.allContentfulEvent.edges;
	const { basePath } = pageContext;
	const allTags = data.allContentfulTag.edges;

	return (
		<ThemeProvider theme={{ mode: 'light' }}>
			<Layout>
				<SEO title="Live Events" />

				<BlogHero>
					<Container>
						<BlogHeader>Live Events</BlogHeader>
					</Container>
				</BlogHero>

				<BlogMain>
					<InnerWrapper>
						<IndexContainer>
							{events.map(({ node: post }, i) => (
								post.title == "dummy post" ? '' :
								<PostCard key={post.id} featured={i}>
									<BlogLinkWrapper featured={i}>
										<ImageLink to={basePath + post.id} featured={i}>
											<Img
												style={{ height: '100%' }}
												backgroundColor={cwTheme.lightBlue}
												sizes={post.mainImage.fluid}
											/>
										</ImageLink>

										<article>
											<PostTitle to={basePath + post.id} featured={i}>
												{post.title}
											</PostTitle>

											<Date featured={i}>{post.timeText + ' ON ' + post.date}</Date>

											<PostExcerpt>{post.description.childMarkdownRemark.excerpt}</PostExcerpt>
											<Button
												link={basePath + post.id}
												hoverColor={cwTheme.primaryHover}
												color={cwTheme.primaryBlue}
												style={{ alignSelf: 'flex-start', marginBottom: '5px' }}
											>
												See Details
											</Button>
										</article>
									</BlogLinkWrapper>
								</PostCard>
							))}
						</IndexContainer>
					</InnerWrapper>
				</BlogMain>

				<Pagination context={pageContext} />

				<SignUpForm header={'Stay in the know about all live events!'} />
			</Layout>
		</ThemeProvider>
	);
};

export const query = graphql`
	query($skip: Int!, $limit: Int!) {
		allContentfulEvent(sort: { fields: [date], order: ASC }, limit: $limit, skip: $skip) {
			totalCount
			edges {
				node {
					title
					id
					link
					linkText
					date(formatString: "MMMM DD, YYYY")
					timeText
					tags {
						title
						slug
						id
					}
					description {
						childMarkdownRemark {
							excerpt
							timeToRead
						}
					}
					mainImage {
						fluid(maxWidth: 1200, maxHeight: 600, quality: 100) {
							src
							...GatsbyContentfulFluid_noBase64
						}
					}
				}
			}
		}
		allContentfulTag(sort: { fields: title, order: ASC }) {
			edges {
				node {
					slug
					title
					id
				}
			}
		}
	}
`;

export default BlogHome;
