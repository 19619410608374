import styled from 'styled-components';
import { cwTheme } from '../theme/theme.js';

const PreHeader = styled.h5`
	color: ${cwTheme.secondaryBlue};
	margin-top: 0;
	font-family: ${cwTheme.sansSerif};
`;

export default PreHeader;
