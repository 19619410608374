import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Wrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	margin-top: 2rem;
	max-width: 250px;
	width: 100%;
`;

const PreviousLink = styled(Link)`
  margin-right: auto;
  order: 1;
  font-size: 10px;
`;

const NextLink = styled(Link)`
  margin-left: auto;
  order: 3;
  font-size: 10px;
`;

const PageIndicator = styled.span`
	position: absolute;
	width: 100%;
	text-align: center;
	z-index: -1;
	font-weight: 500;
	font-size: .707rem;
	margin-top: 5px;
	opacity: .8;
`;

class Pagination extends React.Component {
	render() {
		const { numPages, currentPage, slug } = this.props.context;
		const isFirst = currentPage === 1;
		const isLast = currentPage === numPages;
		const isNotPaginated = isFirst & isLast;

		const prevPageNum = currentPage - 1 === 1 ? `` : currentPage - 1;
		const nextPageNum = currentPage + 1;

		const pathPrefix = typeof slug === 'string' ? `/tag/${slug}` : 'blog';
		const prevPageLink = isFirst ? null : `${pathPrefix}/${prevPageNum}/`;
		const nextPageLink = isLast ? null : `${pathPrefix}/${nextPageNum}/`;

		return (
			<Wrapper>
				{!isFirst && <PreviousLink to={prevPageLink}>Previous Page</PreviousLink>}
				{!isNotPaginated && (
					<PageIndicator>
						{currentPage}/{numPages}
					</PageIndicator>
				)}
				{!isLast && <NextLink to={nextPageLink}>Next Page</NextLink>}
			</Wrapper>
		);
	}
}

export default Pagination;
